export default {
	data: {
		selectedImageIndex: undefined
	},
	beforeMount: function () {
		//console.warn("ha", window.location.search);
		var url = new URL(window.location.href);
		var params = {};
		for (let key of url.searchParams.keys()) {
			//console.log("p", key, url.searchParams.get(key));
			params[key] = url.searchParams.get(key);

		}
		this.searchParams = params;
	},
	computed: {

		mailto: function () {
			return `mailto:${this.emailAddress}`;
		},
		emailAddress: function () {
			return (VOFFICE.contactEmail || ['info', 'test.de']).join('@');
		},
	},

	methods: {
		getBlogPath: function (jsonData) {

			const data = typeof jsonData === "string" ? JSON.parse(jsonData) : jsonData;
			for (const item of data) {
				switch (item.name) {
				case "Stellenangebot":
					return "./stellenangebote";
				case "Angebote":
					return "./angebote";
				default:
					console.log(`Kein spezifischer Fall für: ${item.name}`);
					break;
				}
			}

			return './blog';
		},
		getBlogPathName: function (jsonData) {
			const data = typeof jsonData === "string" ? JSON.parse(jsonData) : jsonData;

			for (const item of data) {
				switch (item.name) {
				case "Stellenangebot":
					return "Stellenangebote";
				case "Angebote":
					return "Angebote";
				default:
					console.log(`Kein spezifischer Fall für: ${item.name}`);
					break;
				}
			}

			// Falls kein passender Wert gefunden wurde, Standardwert zurückgeben
			return "Blog";
		}
	},

};